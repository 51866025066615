import React from 'react'

function aboutAnnihilation() {
    return (
        <div>
            <h1>
                About Annihilation
            </h1>
        </div>
    )
}

export default aboutAnnihilation
