import React from 'react'
import '../Assets/Patch.css';

export default function patch() {
    return (
        <div class="patch_body">
            <h1>🅆🄴🄻🄲🄾🄼🄴 🅃🄾 🄿🄰🅃🄲🄷 🄽🄾🅃🄴 🅂🄴🄲🅃🄸🄾🄽</h1>
            <h2>Our games are coming soon!</h2>
            <h3>We will update our patch notes Here</h3>  
            <p>(Thanks For Your Patience)</p>
            
        </div>
    )
}
